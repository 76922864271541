import NBG from "./images/nbg-gunther.png";
import chef from './images/g-world.jpeg'
import gold from './images/g-gold.jpeg'
import steak from './images/steaky.jpeg'
import { FaTelegram, FaTwitter } from 'react-icons/fa'
import { MdAttachEmail } from "react-icons/md";

function App() {

  function copyTextToClipboard() {
    var textToCopy = document.getElementById("copyText").textContent;

    // Create a temporary textarea element to hold the text
    var textarea = document.createElement("textarea");
    textarea.value = textToCopy;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);

    // Provide feedback to the user (optional)
    alert("Gunther wallet copied to clipboard");
  }

  return (
    <div className="App">
      <section className="first-section">
        <img src={NBG} alt="" width={40} className="rounded-full border-2 ml-10 p-1" />
        <div className="text-[80px] text-center text-shimmer">GUNTHER</div>
        <img src={NBG} alt="" width={500} className="mx-auto" />
        <div className="mx-auto w-fit text-[50px] flex space-x-8 pb-8">

          <a href="https://www.twitter.com/GuntherOnZeta">
            <FaTwitter />
          </a>
          <a href="mailto:guntherrichest@gmail.com">
            <MdAttachEmail />
          </a>
          <a href="https://t.me/GuntheronZeta">
            <FaTelegram />
          </a>

        </div>

      </section>

      <section className="flex md:flex-row flex-col md:px-20 px-10 md:space-x-10 space-y-6 pb-6 min-h-[100vh] items-center bg-black text-white">
        <div className="w-[100%]">
          <h1 className="text-[55px] font-bold text-center">Who is GuNtHeR</h1>
          <h1 className="text-center text-[20px] text-shimmer2">Gunther, often hailed as "the mogul mutt," is a German Shepherd with unimaginable wealth. His taste for luxury is as refined as his investment portfolio, which is heavily diversified across high-yield bones and memes on Zeta. His popularity spreads across the Ecosystem.
            spect from all the Zeta dogs and Cats.</h1>
        </div>
        <div className="">
          <img src={chef} alt="" className="border rounded-[10px]" />

        </div>
      </section>

      <section className={`bg-cover bg-center pb-8 min-h-[100vh] bg-no-repeat bg-opacity-50`}
        style={{
          backgroundImage: `url(${gold})`,
        }}>
        <img src={steak} alt="" width={400} className="rounded-full mx-auto pt-10 glowing-border" />
        <div className="mt-10">

          <div className="">
            <h1 className="text-shimmer3">CA: </h1>
            <div
              className="text-shimmer2 px-8 text-wrap max-w-full"
              id="copyText"
            >
              0xfCE080Ad2F7A8556A8dA520d9076199B7394E813
            </div>

            < a href="https://app.zedaswap.xyz/#/swap?inputCurrency=ETH&outputCurrency=0xfCE080Ad2F7A8556A8dA520d9076199B7394E813" >

              <div
                className="text-black bg-red-600 mx-auto w-fit px-4 py-2 rounded-2xl mt-3 mb-5 glowing-border">Buy on ZedaSwap</div>
            </a>

          </div>



          <h1 className="text-shimmer3">Total Supply: 1,000,000,000</h1>
          <h1 className="text-shimmer3">Ownership: Renounced!</h1>
          <h1 className="text-shimmer3">Tax: 0/0</h1>
        </div>
      </section>

    </div>
  );
}

export default App;
